import { render, staticRenderFns } from "./MainSidebarLayout.vue?vue&type=template&id=27b0c821&scoped=true"
import script from "./MainSidebarLayout.vue?vue&type=script&lang=js"
export * from "./MainSidebarLayout.vue?vue&type=script&lang=js"
import style0 from "./MainSidebarLayout.vue?vue&type=style&index=0&id=27b0c821&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27b0c821",
  null
  
)

export default component.exports