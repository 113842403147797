import projectService from '../services/projects';

const initialState = {
  projects: [],
  projectFilters: {},
  projectsById: {},
  projectLocks: {},
  accessCodeStatus: null,
};

const actions = {
  async updateProjectFilters({ commit }, filters) {
    commit('updateProjectFilters', filters);
  },
  async listProjects({ commit }) {
    const { data } = await projectService.listProjects();
    commit('listProjects', data);
  },
  async submitCode({ commit }, code) {
    commit('setAccessCodeStatus', 'loading');
    await projectService.submitCode(code)
      .catch((error) => {
        commit('setAccessCodeStatus', null);
        const { errorCode } = error.response.data;
        if (errorCode === 'admin-access-code-expired') {
          commit('setAccessCodeStatus', 'error');
        }
        if (errorCode === 'auth-token-expired') {
          // this is a fallback to list projects after successful axios retry
          commit('setAccessCodeStatus', 'success');
        }
      })
      .then((response) => {
        if (response && response.status === 200) {
          commit('setAccessCodeStatus', 'success');
        }
      });
  },
  async getProject({ commit }, uuid) {
    const { data } = await projectService.getProject(uuid);
    commit('getProject', { data, uuid });
  },
  async qrValidate(ctx, qrContent) {
    const { data } = await projectService.qrValidate(qrContent);
    return data;
  },
  async sendInvite(ctx, { uuid, email, name }) {
    await projectService.sendInvite({ uuid, email, name });
  },
  async forceUnlockProject({ dispatch }, uuid) {
    await projectService.forceUnlockProject(uuid).catch((error) => {
      if (!error.response.data || error.response.data.errorCode !== 'project-is-not-locked') {
        dispatch('app/setError', { errorMessage: 'unlock-failed' }, { root: true });
      }
    });
  },
};

const mutations = {
  updateProjectFilters(state, filter) {
    state.projectFilters = filter;
  },
  listProjects(state, projects) {
    // remove lock tokens if projects are not locked
    const lockedUUIDs = projects
      .filter((p) => p.locked)
      .map((p) => p.uuid);
    const projectLock = {};
    const entries = Object.entries(state.projectLocks);
    for (let i = 0; i < entries.length; i += 1) {
      const [uuid, lock] = entries[i];
      if (lockedUUIDs.includes(uuid)) {
        projectLock[uuid] = lock;
      }
    }
    state.projectLocks = projectLock;
    state.projects = projects;
    state.projectsById = projects
      .reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
  },
  setAccessCodeStatus(state, status) {
    state.accessCodeStatus = status;
  },
  getProject(state, payload) {
    state.projectsById = { ...state.projectsById, [payload.uuid]: payload.data };
    state.projects = state.projects.map((p) => {
      if (p.uuid === payload.uuid) {
        p = payload.data;
      }
      return p;
    });
  },
};

const getters = {
  getProjectById: (state) => (uuid) => state.projectsById[uuid] || {},
  projectFilters: (state) => state.projectFilters || {},
  filteredProjectList: (state) => {
    let projects = [...state.projects];
    const projectFilters = state.projectFilters || {};
    projects = projects.filter((projectItem) => {
      const project = projectItem;
      return project.name.toLowerCase().includes((projectFilters.searchText || '').toLowerCase())
          || project.address.formatted.toLowerCase().includes((projectFilters.searchText || '').toLowerCase());
    });

    projects.sort((a, b) => {
      const aCountry = a.address && a.address.country ? a.address.country : '';
      const bCountry = b.address && b.address.country ? b.address.country : '';
      const aCity = a.address && a.address.city ? a.address.city : '';
      const bCity = b.address && b.address.city ? b.address.city : '';
      const aStreet = a.address && a.address.street ? a.address.street : '';
      const bStreet = b.address && b.address.street ? b.address.street : '';

      switch (projectFilters.sort) {
        case 'expire-asc':
          return new Date(b.expiresAt) - new Date(a.expiresAt);
        case 'expire-desc':
          return new Date(a.expiresAt) - new Date(b.expiresAt);
        case 'name-asc':
          return a.name.localeCompare(b.name);
        case 'name-desc':
          return -a.name.localeCompare(b.name);
        case 'location-asc':
          return aCountry.localeCompare(bCountry)
              || aCity.localeCompare(bCity)
              || aStreet.localeCompare(bStreet);
        case 'location-desc':
          return -aCountry.localeCompare(bCountry)
              || -aCity.localeCompare(bCity)
              || -aStreet.localeCompare(bStreet);
        case 'online':
          return a.gatewayConnectedToCloud ? -1 : 1;
        case 'offline':
          return a.gatewayConnectedToCloud ? 1 : -1;
        default:
          return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });
    return projects;
  },
  projectsCount: (state) => state.projects.length,
  isUnlockableProject: (state) => (uuid) => {
    const project = state.projectsById[uuid] || {};
    const projectLock = state.projectLocks[uuid];

    return project.locked && (projectLock && projectLock.token);
  },
  getAccessCodeStatus: (state) => state.accessCodeStatus,
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
