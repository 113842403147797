/* global axios */

const login = async ({ email, password }) => axios({
  method: 'POST',
  url: '/admin/auth/login',
  data: { email, password },
  skipGlobalErrorHandling: true,
});

/*
  Always waits the pending renewToken request
 */
let renewTokenPromise = null;
const renewToken = async () => {
  if (renewTokenPromise) {
    return renewTokenPromise;
  }
  renewTokenPromise = axios({
    method: 'POST',
    url: '/admin/auth/renew-token',
    data: {},
    preventRefreshing: true,
  }).finally(() => {
    renewTokenPromise = null;
  });
  return renewTokenPromise;
};

const confirmEmail = async ({ email, token }) => axios({
  method: 'PATCH',
  url: '/admin/auth/email-confirm',
  data: { email, token },
});

const forgotPassword = async (email) => axios({
  method: 'POST',
  url: '/admin/auth/resetpassword',
  data: { email },
});

const resetPassword = async ({ email, token, password }) => axios({
  method: 'PATCH',
  url: '/admin/auth/resetpassword',
  data: { email, token, password },
});

const logout = async ({ refreshToken }) => axios({
  method: 'POST',
  url: '/admin/auth/logout',
  data: { refreshToken },
  isLogout: true,
});

export default {
  login,
  renewToken,
  confirmEmail,
  forgotPassword,
  resetPassword,
  logout,
};
