/* global axios */

const getUser = async () => axios({
  method: 'GET',
  url: '/admin/me/',
});

const updateUser = async (data) => axios({
  method: 'PATCH',
  url: '/admin/me/',
  data,
});

export default {
  getUser,
  updateUser,
};
