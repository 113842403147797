import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';

dayjs.extend(utc);
dayjs.extend(require('dayjs-timezone-iana-plugin'));

const CHANNELS = ['one', 'two', 'three', 'four'];
const getDefaultButtons = (isEmpty) => CHANNELS.reduce((acc, key, i) => {
  const events = {
    pressed: { action: '', value: { on: true, level: 1 } },
    hold_pressed: { action: '', value: { on: false, level: 1 } },
    released: { action: '', value: { on: false, level: 1 } },
  };
  return { ...acc, [key]: { number: i + 1, target: { type: isEmpty ? 'room' : null }, events } };
}, {});

// parse array actions to object format
export const serializeDeviceActions = (actionArray) => {
  const map = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    pressed: 'pressed',
    'hold pressed': 'hold_pressed',
    released: 'released',
  };
  const isEmpty = !actionArray.length;
  const buttons = getDefaultButtons(isEmpty);

  actionArray.forEach((item) => {
    const number = item.button;
    const { event } = item;
    // set channel target type and uuid
    buttons[map[number]].target.type = item.target.type;
    buttons[map[number]].target.uuid = item.target.uuid;

    // set event properties
    if (item.action) {
      buttons[map[number]].events[map[event]].action = item.action;
    } else if (item.target.type === 'scene') {
      buttons[map[number]].target.type = 'room';
      buttons[map[number]].target.uuid = null; // calculated in SwitchButtonConfig component
      buttons[map[number]].events[map[event]].action = 'scene';
      buttons[map[number]].events[map[event]].sceneId = item.target.uuid;
    }
    if (item.value) {
      if (item.value.level) {
        buttons[map[number]].events[map[event]].value.level = item.value.level;
        buttons[map[number]].events[map[event]].value.on = item.value.on;
      } else {
        buttons[map[number]].events[map[event]].action = item.value.on ? 'on' : 'off';
      }
    }
  });

  return buttons;
};

// parse object actions to array format
export const deserializeDeviceActions = (buttons) => {
  const map = {
    pressed: 'pressed', hold_pressed: 'hold pressed', released: 'released',
  };
  let actions = [];

  CHANNELS.forEach((ch) => {
    const button = buttons[ch];
    const { target } = button;

    if (!target.type || !target.uuid) {
      return;
    }

    Object.entries(button.events)
      .filter(([, value]) => value.action)
      .forEach(([eventType, actionObj]) => {
        const action = {
          button: button.number,
          event: map[eventType],
          target: { ...target },
          action: actionObj.action,
        };
        if (actionObj.action === 'set') {
          action.value = {
            on: actionObj.value.on,
            level: actionObj.value.level,
          };
        }
        if (actionObj.action === 'on') {
          action.action = 'set';
          action.value = {
            on: true,
          };
        }
        if (actionObj.action === 'off') {
          action.action = 'set';
          action.value = {
            on: false,
          };
        }
        if (actionObj.action === 'scene') {
          action.target.type = 'scene';
          action.target.uuid = actionObj.sceneId;
          delete action.action;
        }
        actions = [...actions, action];
      });
  });

  return actions;
};

export const hasOwnProperty = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

/* eslint-disable */
export const uuidv4 = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  const r = Math.random() * 16 | 0; const
    v = c === 'x' ? r : (r & 0x3 | 0x8);
  return v.toString(16);
});

export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

export const formatDate = (datetime, timezone = null) => {
  if (timezone) {
    return dayjs(datetime).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
  }
  return dayjs(datetime).local().format('YYYY-MM-DD HH:mm:ss');
}

export const getFormData = (object, form, namespace) => {
  const formData = form || new FormData()
  for (const property in object) {
    const formKey = namespace ? `${namespace}[${property}]` : property
    if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
      getFormData(object[property], formData, formKey)
    } else {
      formData.append(formKey, object[property])
    }
  }
  return formData
}

export const getLocales = () => {
  return [
    {
      code: 'en',
      name: 'English',
    },
    {
      code: 'de',
      name: 'German',
    },
    {
      code: 'fr',
      name: 'French',
    },
    {
      code: 'es',
      name: 'Spanish',
    },
    {
      code: 'it',
      name: 'Italian',
    },
    {
      code: 'nl',
      name: 'Dutch',
    },
    {
      code: 'pt',
      name: 'Portuguese',
    }
  ]
}

export const getLegalTypes = () => {
  return ['tos', 'imprint', 'licenses', 'privacy', 'troubleshooting'];
}

export const trimHexString = (hexString) => {
  const hex = hexString.toUpperCase();
  return  hex.indexOf('0X') > -1 ? hex.substring(2) : hex;
}
