import Vue from 'vue';

/**
  Active events:
    bus.$emit('forceRerender') - will re-render the actual route
    bus.$emit('projectUpdated') - will re-render the actual route if user has no lock token
    bus.$emit('showAccessProjectModal') - will open access project modal
 */

export default new Vue();
