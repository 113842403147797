import Vue from 'vue';
import {
  USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_PROJECT,
} from '../actions/user';
import userService from '../services/user';

const initialState = {
  status: '', profile: {}, project: '', projectLock: {},
};

const getters = {
  getProfileData: (state) => state.profile || {},
  isProfileLoaded: (state) => !!state.profile.name,
  userProject: (state) => state.project,
  projectLockToken: (state) => (uuid) => {
    const lock = state.projectLock[uuid];
    return lock ? lock.token : null;
  },
  pendingInvitations: (state) => (state.profile.pendingInvitations || [])
    .filter((inv) => inv.project),
};

const actions = {
  getProfile: async ({ commit, dispatch, rootState }) => {
    commit(USER_REQUEST);
    await userService.getUser(rootState.auth.userId).then((resp) => {
      commit(USER_SUCCESS, resp.data);
    }).catch(() => {
      commit(USER_ERROR);
      dispatch('auth/logout', null, { root: true });
    });
  },
  [USER_PROJECT]: ({ commit }, payload) => {
    commit(USER_PROJECT, payload);
  },
  unlockProject: ({ commit }, payload) => {
    commit('unlockProject', payload);
  },
  async updateUser(ctx, data) {
    await userService.updateUser(data);
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [USER_SUCCESS]: (state, payload) => {
    state.status = 'success';
    Vue.set(state, 'profile', payload);
  },
  [USER_ERROR]: (state) => {
    state.status = 'error';
  },
  [USER_PROJECT]: (state, payload) => {
    state.projectLock = { ...state.projectLock, [payload.uuid]: payload };
  },
  unlockProject: (state, payload) => {
    delete state.projectLock[payload];
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
