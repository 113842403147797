import firmwareService from '@/store/services/firmwares';

const initialState = {
  radioFilters: {
    selected: 'gateways',
    product: '',
  },
  savedSort: null,
  firmwares: [],
};

const actions = {
  radioFiltersUpdate({ commit }, filters) {
    commit('radioFiltersUpdate', filters);
  },
  async listFirmwares({ commit }, { productUUID }) {
    const { data } = await firmwareService.listFirmwares({ productUUID, version: '0.0.0.0' });
    commit('listFirmwares', { data });
  },
  async uploadFirmware(ctx, firmware) {
    await firmwareService.uploadFirmware(firmware);
  },
  async editFirmware(ctx, { uuid, data }) {
    await firmwareService.editFirmware({ uuid, data });
  },
  async deleteFirmware(ctx, { uuid }) {
    await firmwareService.deleteFirmware(uuid);
  },
  savedSortUpdate({ commit }, savedSort) {
    commit('savedSortUpdate', savedSort);
  },
};

const mutations = {
  radioFiltersUpdate: (state, filters) => {
    state.radioFilters = filters;
  },
  listFirmwares: (state, payload) => {
    state.firmwares = payload.data || [];
  },
  savedSortUpdate: (state, savedSort) => {
    state.savedSort = savedSort;
  },
};

const getters = {
  getFirmwares: (state) => state.firmwares || [],
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
