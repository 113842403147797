import contentService from '@/store/services/contents';
import { getLocales, getLegalTypes } from '@/utils/helpers';
import _defaultsDeep from 'lodash/defaultsDeep';

const initialState = {
  countryCode: 'default',
  countries: [],
  legalContents: {},
  legalContentsLoaded: false,
};

const actions = {
  changeCountry({ commit }, countryCode) {
    commit('changeCountry', countryCode);
  },
  async getCountries({ commit, rootGetters }) {
    const { data } = await contentService.getCountries();
    commit('getCountries', { data, user: rootGetters['user/getProfileData'] });
  },
  async getLegalContents({ commit }) {
    const { data } = await contentService.getLegalContents();
    commit('getLegalContents', data);
  },
  async updateLegalContents({ state }, contents) {
    const data = state.countryCode === 'default'
      ? { default: contents }
      : { default: {}, [state.countryCode]: contents };
    await contentService.updateLegalContents(data);
  },
};

const mutations = {
  changeCountry: (state, countryCode) => {
    state.countryCode = countryCode;
  },
  getCountries: (state, { data, user }) => {
    if (!user || user.role === 'super_admin') {
      state.countries = data;
      return;
    }

    const businessRegionName = user.country?.businessRegionName;
    if (!businessRegionName) {
      state.countries = [];
      return;
    }
    state.countries = data.filter((country) => country.businessRegionName === businessRegionName);
  },
  getLegalContents: (state, data) => {
    state.legalContentsLoaded = false;
    let contents = {};
    const locales = getLocales();
    const types = getLegalTypes();
    const countries = [{ code: 'default', name: 'DEFAULT' }, ...state.countries
      .map((country) => ({ code: country.countryCode, name: country.name }))];

    // creating contents object with empty properties
    const contentsLocale = types
      .map((type) => ({ [type]: { url: '', content: null } }))
      .reduce((acc, item) => ({ ...acc, ...item }), {});
    const contentsCountry = locales
      .map((locale) => ({ [locale.code]: contentsLocale }))
      .reduce((acc, item) => ({ ...acc, ...item }), {});
    contents = countries
      .map((country) => ({ [country.code]: contentsCountry }))
      .reduce((acc, item) => ({ ...acc, ...item }), {});

    // setting existing values of properties by merging the two object using lodash defaultsDeep
    state.legalContents = _defaultsDeep(data, contents);
    state.legalContentsLoaded = true;
  },
};

const getters = {
  getCountryCode: (state) => state.countryCode,
  getCountryList: (state) => state.countries,
  getLegalCountryList: (state) => [{ code: 'default', name: 'DEFAULT' }, ...state.countries
    .map((country) => ({ code: country.countryCode, name: country.name }))],
  getDefaultContents: (state) => state.legalContents.default,
  getCountryContents: (state) => state.legalContents[state.countryCode],
  countriesLoaded: (state) => state.countries.length > 0,
  legalContentsLoaded: (state) => state.legalContentsLoaded,
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
