/* global axios */

const listProjects = async () => axios({
  method: 'GET',
  url: '/admin/projects',
});

const getProject = async (uuid) => axios({
  method: 'GET',
  url: `/admin/projects/${uuid}`,
});

const submitCode = async (code) => axios({
  method: 'POST',
  url: '/admin/projects/submit-code',
  data: { code },
});

const qrValidate = async (qrContent) => axios({
  method: 'POST',
  url: '/admin/projects/qr-validate',
  data: { qrContent },
  skipGlobalErrorHandling: true,
});

const sendInvite = async ({ uuid, email, name }) => axios({
  method: 'POST',
  url: `/admin/projects/${uuid}/invite`,
  data: { email, name },
});

const forceUnlockProject = async (uuid) => axios({
  method: 'DELETE',
  url: `/admin/projects/${uuid}/lock/forced`,
  skipGlobalErrorHandling: true,
});

export default {
  listProjects,
  getProject,
  submitCode,
  forceUnlockProject,
  qrValidate,
  sendInvite,
};
