import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthLayout from '@/components/layouts/AuthLayout.vue';
import MainLayout from '@/components/layouts/MainLayout.vue';
import MainSidebarLayout from '@/components/layouts/MainSidebarLayout.vue';

import Login from '@/views/auth/Login.vue';
import Confirm from '@/views/auth/Confirm.vue';
import Success from '@/views/auth/Success.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';

import Home from '@/views/home/Home.vue';
import Projects from '@/views/projects/Projects.vue';
import Firmware from '@/views/products/Firmware.vue';
import LegalDocuments from '@/views/settings/LegalDocuments.vue';
import Admins from '@/views/settings/Admins.vue';
import Catalog from '@/views/products/Catalog.vue';
import ProductDetails from '@/views/products/ProductDetails.vue';
import FamilyMeta from '@/views/products/FamilyMeta.vue';

import Settings from '@/views/profile/Settings.vue';
import ProductsSidebar from '@/components/layouts/navbars/sidebar/ProductsSidebar.vue';
import SettingsSidebar from '@/components/layouts/navbars/sidebar/SettingsSidebar.vue';
import store from '../store';

const TAB_ID = Math.floor(Math.random() * 10000000);

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/isAuthenticated']) {
    next();
    return;
  }
  next('/projects');
};

const trackTabs = async (to) => {
  if (to.params && to.params.projectId) {
    await store.dispatch('app/trackTab', { projectId: to.params.projectId, tabId: TAB_ID });
  } else {
    await store.dispatch('app/trackTab', { projectId: undefined, tabId: TAB_ID });
  }
};

const ifAuthenticated = async (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    await trackTabs(to, from);
    next();
    return;
  }
  next('/login');
};

const redirectIfAuthed = async (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next('/projects');
    return;
  }
  next('/login');
};

const generalRoutes = [
  {
    path: '/',
    beforeEnter: redirectIfAuthed,
  },
  {
    path: '/error/404',
    name: 'error-404',
    component: () => import('@/views/errors/404.vue'),
    meta: { layout: AuthLayout },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
    meta: { layout: AuthLayout },
  },
  {
    path: '/logout',
    name: 'logout',
    async beforeEnter(to, from, next) {
      await store.dispatch('auth/logout');
      next('/login');
    },
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm,
    meta: { layout: AuthLayout },
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
    beforeEnter(to, from, next) {
      if (store.getters['auth/isAuthenticated']) {
        next({ path: '/settings/profile' });
      } else {
        next();
      }
    },
    meta: { layout: AuthLayout },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    beforeEnter: ifNotAuthenticated,
    meta: { layout: AuthLayout },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
      },
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: { showProjectFilter: true },
      },
    },
  },
  {
    path: '/products/firmware',
    name: 'products-firmware',
    alias: '/products',
    component: Firmware,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainSidebarLayout,
        options: {
          browserBack: true,
          sidebarComponent: ProductsSidebar,
          breadcrumbs: [
            { title: 'pageFirmware' },
          ],
        },
      },
    },
  },
  {
    path: '/products/catalog',
    name: 'products-catalog',
    component: Catalog,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainSidebarLayout,
        options: {
          browserBack: true,
          sidebarComponent: ProductsSidebar,
          breadcrumbs: [
            { title: 'pageCatalog' },
          ],
        },
      },
    },
  },
  {
    path: '/products/families',
    name: 'products-families',
    component: FamilyMeta,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainSidebarLayout,
        options: {
          browserBack: true,
          sidebarComponent: ProductsSidebar,
          breadcrumbs: [
            { title: 'pageFamilyMeta' },
          ],
        },
      },
    },
  },
  {
    path: '/products/catalog/:productId/details',
    name: 'products-details',
    component: ProductDetails,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainSidebarLayout,
        options: {
          breadcrumbs: [
            { routeName: 'products-catalog', title: 'pageCatalog', back: true },
            { title: '$CURRENT_PRODUCT_NAME' },
          ],
        },
      },
    },
  },
  {
    path: '/settings/legal',
    name: 'settings-legal',
    alias: '/settings',
    component: LegalDocuments,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainSidebarLayout,
        options: {
          browserBack: true,
          sidebarComponent: SettingsSidebar,
          breadcrumbs: [
            { title: 'pageLegalDocuments' },
          ],
        },
      },
    },
  },
  {
    path: '/settings/admins',
    name: 'settings-admin',
    component: Admins,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainSidebarLayout,
        options: {
          browserBack: true,
          sidebarComponent: SettingsSidebar,
          breadcrumbs: [
            { title: 'pageAdministrators' },
          ],
        },
      },
    },
  },
  {
    path: '/settings/profile',
    name: 'settings-profile',
    component: Settings,
    beforeEnter: ifAuthenticated,
    meta: {
      layout: {
        component: MainLayout,
        options: {
          breadcrumbs: [
            { title: 'pageProfileSettings' },
          ],
        },
      },
    },
  },
];

const errorRoutes = [
  { path: '*', redirect: '/error/404' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...generalRoutes,
    ...errorRoutes,
  ],
});

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  next();
};
router.beforeEach(waitForStorageToBeReady);

export default router;
