import adminService from '@/store/services/admins';

const initialState = {
  admins: [],
};

const actions = {
  async getAdmins({ commit }) {
    const { data } = await adminService.getAdmins();
    commit('getAdmins', { data });
  },
  async createAdmin(ctx, params) {
    await adminService.createAdmin(params);
  },
  async updateAdmin(ctx, params) {
    await adminService.updateAdmin(params);
  },
  async deleteAdmin(ctx, params) {
    await adminService.deleteAdmin(params);
  },
};

const mutations = {
  getAdmins: (state, { data }) => {
    state.admins = data;
  },
};

const getters = {

};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
