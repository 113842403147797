/* global axios */

const getAdmins = () => axios({
  method: 'GET',
  url: '/admin/admins',
});

const createAdmin = ({ data }) => axios({
  method: 'POST',
  url: '/admin/admins',
  data,
});

const updateAdmin = ({ uuid, data }) => axios({
  method: 'PATCH',
  url: `/admin/admins/${uuid}`,
  data,
});

const deleteAdmin = ({ uuid }) => axios({
  method: 'DELETE',
  url: `/admin/admins/${uuid}`,
});

export default {
  getAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
};
