import appService from '../services/app';
import i18n from '../../i18n';

const encoder = new TextDecoder('iso-8859-2');

const initialState = {
  error: null,
  projectUpdatedState: {
    updated: false,
    data: null,
  },
  appData: {},
  floorplanOpened: false,
  tabsWithProject: {},
  locale: 'en',
  selectedView: 'table',
};

const actions = {
  floorplanUpdate({ commit }, opened) {
    commit('floorplanUpdate', opened);
  },
  async getAppData({ commit }) {
    const { data } = await appService.getAppData();
    commit('getAppData', data);
  },
  trackTab({ commit }, { tabId, projectId }) {
    commit('trackTab', { tabId, projectId });
  },
  changeLocale({ commit, state }, locale) {
    const targetLocale = locale || state.locale;
    i18n.locale = targetLocale;
    i18n._vm.$root.locale = targetLocale;
    commit('changeLocale', targetLocale);
  },
  // use: dispatch('app/setError', null, { root: true });
  setError({ commit }, error) {
    commit('setError', { error });
  },
  clearError({ commit }) {
    commit('setError', { error: null });
  },
  changeListView({ commit }, val) {
    commit('changeListView', val);
  },
  projectUpdatedEvent({ commit }, { updated, data }) {
    commit('projectUpdatedEvent', { updated, data });
  },
  async getLegalDocument(ctx, { locale, type }) {
    const { data } = await appService.getLegalDocument({ locale, type });
    return encoder.decode(data);
  },
};

const mutations = {
  getAppData(state, data) {
    state.appData = data;
  },
  trackTab: (state, { tabId, projectId }) => {
    const tabs = { ...state.tabsWithProject };
    if (!projectId) {
      delete tabs[tabId];
      state.tabsWithProject = { ...tabs };
      return;
    }
    state.tabsWithProject = { ...tabs, [tabId]: projectId };
  },
  changeLocale: (state, locale) => {
    state.locale = locale;
  },
  floorplanUpdate: (state, opened) => {
    state.floorplanOpened = opened;
  },
  setError: (state, data) => {
    const { error } = data;
    console.log(1);
    console.log(error);
    const errorMessage = {
      hasError: !!error,
      title: '',
      message: '',
    };
    if (!errorMessage.hasError) {
      state.error = errorMessage;
      return;
    }
    if (error.errorMessage === 'Validation error') {
      errorMessage.title = 'Validation error';
      errorMessage.message = error.errorDetails[0].message;
    } else if (error.errorMessage === 'unlock-failed') {
      errorMessage.title = 'Unlock failed';
      errorMessage.message = 'Project unlock failed';
    } else if (error.errorMessage === 'Bad request') {
      errorMessage.title = 'Bad request';
      errorMessage.message = error.errorDetails[0].message;
    } else if (error.errorMessage === 'You cannot delete yourself') {
      errorMessage.title = 'Delete failed';
      errorMessage.message = 'You cannot delete yourself';
    } else if (error.errorMessage) {
      errorMessage.title = error.errorMessage;
      // TODO translate these codes
      errorMessage.message = error.errorCode;
    }
    state.error = errorMessage;
  },
  changeListView(state, view) {
    state.selectedView = view;
  },
  projectUpdatedEvent(state, { updated, data }) {
    state.projectUpdatedState = { updated, data };
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
