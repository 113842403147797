import productService from '../services/products';

const initialState = {
  radioFilters: {
    selected: 'lights',
    selectedRegionCountry: null,
  },
  savedSort: null,
  products: [],
  productsById: {},
  productsByCategory: {},
  eanCodes: [],
  productProperties: [],
  requestSent: false,
  pimcoreProductsByEan: {},
  familiesMeta: [],
};

const actions = {
  radioFiltersUpdate({ commit }, filters) {
    commit('radioFiltersUpdate', filters);
  },
  async listProducts({ commit } = {}) {
    const { data } = await productService.listProducts();
    commit('listProducts', data);
  },
  async getProduct({ commit }, productUUID) {
    const { data } = await productService.getProduct(productUUID);
    commit('getProduct', { productUUID, data });
  },
  async deleteProduct(ctx, productUUID) {
    await productService.deleteProduct(productUUID);
  },
  async getProperties({ commit }) {
    const { data } = await productService.getProperties();
    commit('setProductProperties', data);
  },
  async createProduct(ctx, product) {
    ctx.commit('setRequestSent', false);
    const { data } = await productService.createProduct(product).finally(() => {
      ctx.commit('setRequestSent', true);
    });
    return data;
  },
  async uploadProductImages(ctx, { productUUID, images }) {
    await productService.uploadImages({ productUUID, images });
  },
  async deleteProductImages(ctx, { productUUID, attachmentUUIDs }) {
    await productService.deleteImages({ productUUID, attachmentUUIDs });
  },
  async updateProduct(ctx, { productUUID, data }) {
    ctx.commit('setRequestSent', false);
    await productService.updateProduct({ productUUID, data }).finally(() => {
      ctx.commit('setRequestSent', true);
    });
  },
  async getProductFromPimcore(ctx, { ean, category }) {
    const { data } = await productService.getProductFromPimcore({ ean, category });
    ctx.commit('getProductFromPimcore', { ean, ...data });
    return data;
  },
  async importProductFromPimcore(ctx, { product, updateExisting }) {
    ctx.commit('setRequestSent', false);
    const { data } = await productService.importProductFromPimcore({ product, updateExisting })
      .finally(() => {
        ctx.commit('setRequestSent', true);
      });
    return data;
  },
  async findDifferencesFromPimcore(ctx) {
    ctx.commit('setRequestSent', false);
    const { data } = await productService.findDifferencesFromPimcore().finally(() => {
      ctx.commit('setRequestSent', true);
    });
    return data;
  },
  async listFamiliesMeta(ctx) {
    const { data } = await productService.listFamiliesMeta();
    ctx.commit('listFamiliesMeta', data);
    return data;
  },
  async uploadFamilyMetaImage(ctx, { familyMetaId, image }) {
    ctx.commit('setRequestSent', false);
    await productService.uploadFamilyMetaImage({ familyMetaId, image }).finally(() => {
      ctx.commit('setRequestSent', true);
    });
  },
  savedSortUpdate({ commit }, savedSort) {
    commit('savedSortUpdate', savedSort);
  },
  async setAsDefaultAttachment(ctx, { attachmentUUID }) {
    await productService.setAsDefaultAttachment({ attachmentUUID });
  },
};

const mutations = {
  listProducts(state, data) {
    const deviceCategories = ['lights', 'switches', 'sensors', 'gateways'];
    const deviceCategoriesWithoutGateWay = ['lights', 'switches', 'sensors'];
    const emptyState = {
      gateways: [], lights: [], switches: [], sensors: [],
    };
    state.products = { ...emptyState, ...data };
    state.productsById = deviceCategories.map((cat) => {
      const devicesByCat = data.filter((p) => p.category === cat)
        .sort((a, b) => a.name.localeCompare(b.name));
      state.productsByCategory = { ...state.productsByCategory, [cat]: devicesByCat };
      return devicesByCat
        .reduce((acc, item) => ({ ...acc, [item.uuid]: item }), {});
    }).reduce((acc, item) => ({ ...acc, ...item }), {});

    const allCategory = deviceCategoriesWithoutGateWay.reduce((acc, category) => {
      acc = [...acc, ...data.filter((product) => product.category === category)];
      return acc;
    }, []);
    state.productsByCategory = { ...state.productsByCategory, all: allCategory };

    const eanCodes = Object.values(data).map((product) => product.ean);
    state.eanCodes = [...new Set(eanCodes)];
  },
  getProduct(state, { productUUID, data }) {
    state.productsById = { ...state.productsById, [productUUID]: data };
  },
  radioFiltersUpdate: (state, filters) => {
    state.radioFilters = filters;
  },
  setProductProperties: (state, properties) => {
    state.productProperties = properties.map((property) => {
      if (property.type === 'string' && property.key === 'qrFormat') {
        property.type = 'select';
        property.options = [{ value: '', label: 'N/A' }, { value: 'dotdot', label: 'DotDot' }, { value: 'schneider', label: 'Schneider' }];
      }
      return property;
    });
  },
  setRequestSent: (state, boolean) => {
    state.requestSent = boolean;
  },
  getProductFromPimcore: (state, data) => {
    state.pimcoreProductsByEan = { ...state.pimcoreProductsByEan, [data.ean]: data };
  },
  listFamiliesMeta: (state, data) => {
    state.familiesMeta = data;
  },
  savedSortUpdate: (state, savedSort) => {
    state.savedSort = savedSort;
  },
};

const getters = {
  getProductById: (state) => (productId) => state.productsById[productId] || {},
  getProductsByCategory: (state) => (category) => state.productsByCategory[category] || [],
  productsLoaded: (state) => state.products.length > 0,
  getAllProducts: (state) => state.productsByCategory,
  isEanCodeExists: (state) => (eanCode) => !!state.eanCodes.find((ean) => ean === eanCode),
  getAllEan: (state) => state.eanCodes,
  getProductProperties: (state) => state.productProperties,
  getPimcoreProductByEan: (s) => (ean) => s.pimcoreProductsByEan[ean]?.pimcoreProduct || null,
  getPimcoreImportDataByEan: (s) => (ean) => s.pimcoreProductsByEan[ean] || null,
  getFamiliesMeta: (s) => s.familiesMeta || [],
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
