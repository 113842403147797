import { render, staticRenderFns } from "./ProductEditor.vue?vue&type=template&id=bbab4bce&scoped=true"
import script from "./ProductEditor.vue?vue&type=script&lang=js"
export * from "./ProductEditor.vue?vue&type=script&lang=js"
import style0 from "./ProductEditor.vue?vue&type=style&index=0&id=bbab4bce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbab4bce",
  null
  
)

export default component.exports