/* global axios */

import { getFormData } from '@/utils/helpers';

const listFirmwares = async ({ productUUID, version }) => axios({
  method: 'POST',
  url: '/admin/firmwares/list',
  data: {
    identities: [{
      productUUID,
      version,
    }],
  },
});

const uploadFirmware = async (firmware) => {
  const formData = getFormData(firmware);

  return axios({
    headers: { 'content-type': 'multipart/form-data' },
    method: 'POST',
    url: '/admin/firmwares',
    data: formData,
  });
};

const editFirmware = async ({ uuid, data }) => axios({
  method: 'PATCH',
  url: `/admin/firmwares/${uuid}`,
  data,
});

const deleteFirmware = async (uuid) => axios({
  method: 'DELETE',
  url: `/admin/firmwares/${uuid}`,
});

export default {
  listFirmwares,
  uploadFirmware,
  editFirmware,
  deleteFirmware,
};
