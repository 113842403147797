/* global axios */

const getCountries = () => axios({
  method: 'GET',
  url: '/admin/countries',
});

const getLegalContents = () => axios({
  method: 'GET',
  url: '/admin/legal-contents',
  data: {
    default: {},
  },
});
const updateLegalContents = (data) => axios({
  method: 'PATCH',
  url: '/admin/legal-contents',
  data,
});

export default {
  getCountries,
  getLegalContents,
  updateLegalContents,
};
