import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import localForage from 'localforage';
import createMultiTabState from 'vuex-multi-tab-state';

import admins from '@/store/modules/admins';
import app from '@/store/modules/app';
import auth from '@/store/modules/auth';
import contents from '@/store/modules/contents';
import firmwares from '@/store/modules/firmwares';
import products from '@/store/modules/products';
import projects from '@/store/modules/projects';
import user from '@/store/modules/user';
import vuexStateFixer from '../utils/vuex-state-fixer';

Vue.use(Vuex);

const modules = {
  admins,
  app,
  auth,
  contents,
  firmwares,
  products,
  projects,
  user,
};

const fixState = vuexStateFixer(modules);
const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
  reducer(state) {
    if (!state.auth.userId) {
      return {};
    }
    fixState(state);
    return { ...state };
  },
});

const store = new Vuex.Store({
  plugins: [
    vuexLocal.plugin,
    createMultiTabState({
      statesPaths: ['auth', 'projects'],
    }),
  ],
  modules,
  state: {
    packageVersion: process.env.PACKAGE_VERSION,
    apiVersion: null,
  },
  getters: {
  },
  mutations: {
    setApiVersion: (state, payload) => {
      state.apiVersion = payload.version;
    },
  },
});

export default store;
