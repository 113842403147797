/* global axios */

const createProduct = async (product) => axios({
  method: 'POST',
  url: '/admin/products',
  data: product,
});

const listProducts = async () => axios({
  method: 'GET',
  url: '/admin/products',
});

const deleteProduct = async (productUUID) => axios({
  method: 'DELETE',
  url: `/admin/products/${productUUID}`,
  data: {},
});

const updateProduct = async ({ productUUID, data }) => axios({
  method: 'PUT',
  url: `/admin/products/${productUUID}`,
  headers: { 'content-type': 'application/json' },
  data,
});

const getProperties = async () => axios({
  method: 'GET',
  url: '/admin/products/properties',
});

const getProduct = async (productUUID) => axios({
  method: 'GET',
  url: `/admin/products/${productUUID}`,
});

const uploadImages = async ({ productUUID, images }) => {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const image of images) {
    formData.append('images', image);
  }

  return axios({
    headers: { 'content-type': 'multipart/form-data' },
    method: 'PUT',
    url: `/admin/products/${productUUID}/images`,
    data: formData,
  });
};

const deleteImages = async ({ productUUID, attachmentUUIDs }) => axios({
  method: 'DELETE',
  url: `/admin/products/${productUUID}/images`,
  data: { attachmentUUIDs },
});

const getProductFromPimcore = async ({ ean, category }) => axios({
  method: 'GET',
  url: '/admin/products/pimcore',
  params: {
    ean,
    category,
  },
  skipGlobalErrorHandling: true,
});

const importProductFromPimcore = async ({ product, updateExisting }) => axios({
  method: 'POST',
  url: '/admin/products/pimcore',
  data: product,
  params: { updateExisting },
});

const findDifferencesFromPimcore = async () => axios({
  method: 'POST',
  url: '/admin/products/pimcore/find-differences',
});

const listFamiliesMeta = async () => axios({
  method: 'GET',
  url: '/admin/products/families',
});

const uploadFamilyMetaImage = async ({ familyMetaId, image }) => {
  const formData = new FormData();
  formData.append('image', image);

  return axios({
    headers: { 'content-type': 'multipart/form-data' },
    method: 'PUT',
    url: `/admin/products/families/${familyMetaId}/image`,
    data: formData,
  });
};

const setAsDefaultAttachment = async ({ attachmentUUID }) => axios({
  method: 'PUT',
  url: `/admin/attachments/${attachmentUUID}`,
  data: {},
});

export default {
  createProduct,
  listProducts,
  deleteProduct,
  updateProduct,
  getProperties,
  getProduct,
  uploadImages,
  deleteImages,
  getProductFromPimcore,
  importProductFromPimcore,
  findDifferencesFromPimcore,
  listFamiliesMeta,
  uploadFamilyMetaImage,
  setAsDefaultAttachment,
};
