/* global axios */
import config from '../../config';

const getAppData = async (data) => {
  let url = config.API_URL;

  [url] = url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/img);
  return axios({
    baseURL: url,
    method: 'GET',
    data,
    skipGlobalErrorHandling: true,
  });
};

const getLegalDocument = ({ locale, type }) => axios({
  method: 'GET',
  url: '/legal-document',
  params: { locale, type },
  responseType: 'arraybuffer',
});

export default {
  getAppData,
  getLegalDocument,
};
