import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueTimepicker from 'vue2-timepicker';
import PortalVue from 'portal-vue';
import vSelect from 'vue-select';

import 'bootstrap';
import './main.scss';

import App from './App.vue';
import router from './router';
import store from './store';

import 'vue2-timepicker/dist/VueTimepicker.css';

import './utils/axios';
import './utils/vee-validate';
import './mixins';

import i18n from './i18n';

vSelect.props.components.default = () => ({
  Deselect: {
    render: (createElement) => createElement('span', ''),
  },
  OpenIndicator: {
    render: (createElement) => createElement('span', ''),
  },
});

Vue.use(PortalVue);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.component('vue-timepicker', VueTimepicker);
Vue.component('v-select', vSelect);

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount('#app');
